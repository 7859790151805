import React from "react";

import Title from "@components/UI-Elements/Typography/Title";
import Layout from "@components/layout";
import SEO from "@components/seo";
import Card from "@components/UI-Elements/Card";
import TradingEconomicChart from "@components/Modules/dashboard/global-economic/trading-economic";
import { Section } from "@components/UI-Elements/General";

const title = "Markt Dashboard";
const Dashboard = () => {
  return (
    <Layout>
      <SEO keywords={["finance", "dashboard"]} title={title} robots="noindex" />
      <Title>{title}</Title>

      <Section>
        <Title level={2}>USA</Title>
        <div className="grid gap-5 md:grid-cols-2">
          <Card title="USA Geldmenge M2">
            <TradingEconomicChart type="usa-money-supply-m2" />
          </Card>

          <Card title="USA Inflation (CPI)">
            <TradingEconomicChart type="usa-inflation-cpi" />
          </Card>
        </div>
      </Section>
      <Section>
        <Title level={2}>Europa</Title>
        <div className="grid gap-5 md:grid-cols-2">
          <Card title="Euro Geldmenge M2">
            <TradingEconomicChart type="euro-zone-supply-m2" />
          </Card>

          <Card title="Euro Inflation (CPI)">
            <TradingEconomicChart type="euro-zone-inflation-cpi" />
          </Card>
        </div>
      </Section>
    </Layout>
  );
};

export default Dashboard;
