import Link from "@components/UI-Elements/Navigation/Link";
import * as React from "react";

const chartTypes = {
  "usa-money-supply-m2": {
    iframe:
      "https://d3fy651gv2fhd3.cloudfront.net/embed/?s=unitedstamonsupm2&v=202208271002V20220312&d1=19221009",
    url: "https://tradingeconomics.com/united-states/money-supply-m2",
  },
  "usa-inflation-cpi": {
    iframe:
      "https://d3fy651gv2fhd3.cloudfront.net/embed/?s=cpi+yoy&v=202209131509V20220312&d1=19970920",
    url: "https://tradingeconomics.com/united-states/inflation-cpi",
  },
  "euro-zone-supply-m2": {
    iframe:
      "https://d3fy651gv2fhd3.cloudfront.net/embed/?s=emuevolvmonsupm2&v=202208260839V20220312&d1=19221009",
    url: "https://tradingeconomics.com/euro-area/money-supply-m2",
  },
  "euro-zone-inflation-cpi": {
    iframe:
      "https://d3fy651gv2fhd3.cloudfront.net/embed/?s=eccpemuy&v=202208310925V20220312&d1=20120916",
    url: "https://tradingeconomics.com/euro-area/inflation-cpi",
  },
};
type ChartTypes = keyof typeof chartTypes;

type Props = {
  type: ChartTypes;
  height?: string;
  width?: string;
};
const TradingEconomicChart: React.FC<Props> = ({
  type,
  width = "100%",
  height = "300",
}) => {
  const chart = chartTypes[type];
  const src = `${chart.iframe}&h=${height}&w=600`;

  return (
    <>
      <iframe
        src={src}
        height={height}
        width={width}
        frameBorder="0"
        scrolling="no"
      ></iframe>
      Mehr infos auf{" "}
      <Link href={chart.url} external>
        tradingeconomics.com
      </Link>
    </>
  );
};
export default TradingEconomicChart;
